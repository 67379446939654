import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiScheduledJobExecutionDetails } from '../../api';

export const fetchById = createAsyncThunk(
  'scheduledJobExecutionDetails/findById',
  async (params: {id: number}, { rejectWithValue }) => {
    try {
      return await ApiScheduledJobExecutionDetails.findById(params.id);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);
