import { createSlice } from '@reduxjs/toolkit';
import { PageResponse } from '../../types/PageResponse';
import { ScheduledJobDto } from '../../types/ScheduledJobDto';
import { fetchAll, trigger } from './thunks';

export interface ScheduledJobState {
  result?: PageResponse<ScheduledJobDto>;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  triggered?: boolean;
}

const initialState: ScheduledJobState = {
  result: undefined,
  loading: false,
  error: false,
  errorMessage: undefined,
  triggered: false,
};

export const scheduledJobSlice = createSlice({
  name: 'scheduledJobSlice',
  initialState,
  reducers: {
    resetTriggerState(state) {
      state.triggered = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.pending, (state) => {
      state.result = undefined;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.loading = true;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });

    builder.addCase(trigger.pending, (state) => {
      state.triggered = false;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(trigger.fulfilled, (state, action) => {
      state.triggered = true;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(trigger.rejected, (state, action) => {
      state.loading = true;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });
  },
});

export const {resetTriggerState} = scheduledJobSlice.actions;

export default scheduledJobSlice.reducer;
