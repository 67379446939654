import { createSlice } from "@reduxjs/toolkit";
import { error } from "console";
import { login } from "./thunks";

export interface LoginState {
  loggedIn: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: LoginState = {
  loggedIn: false,
  loading: false,
  error: false,
  errorMessage: undefined
}

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const loginSuccessful = action.payload;
      
      state.loading = false;

      if (loginSuccessful === true) {
        state.loggedIn = true;
        state.error = false;
      } else {
        state.loggedIn = false;
        state.error = true;
        state.errorMessage = "Login unsuccessful"
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loggedIn = false;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });
  }
});

export const {} = loginSlice.actions;

export default loginSlice.reducer;