import { Form } from 'antd';
import styled from 'styled-components';
import { mediaQuery } from '../../util/mediaQuery';

export const LoginFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoginForm = styled(Form)`
  min-width: 100%;

  ${mediaQuery('md')} {
    min-width: 20rem;
  }
`;

export const LoginFormButtonContainer = styled(Form.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
