import axios from 'axios';
import { REACT_APP_API_SERVER_URL } from '../../constants';
import { PageRequest } from '../types/PageRequest';
import { PageResponse } from '../types/PageResponse';
import { ScheduledJobDto } from '../types/ScheduledJobDto';
import { StorageUserDto } from '../types/StorageUserDto';

const base64 = require("base-64");

axios.interceptors.request.use((request) => {
  if (request.url?.indexOf('api/auth/me') === -1) {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser: StorageUserDto = JSON.parse(user);
      request.headers.Authorization = 'Basic ' + base64.encode(parsedUser.username + ":" + parsedUser.password);
    }
  }
  return request;
});

export const findAll = async (pageRequest: PageRequest) => {
  const response = await axios.get<PageResponse<ScheduledJobDto>>(
    `${REACT_APP_API_SERVER_URL}/api/job`,
    {
      params: { ...pageRequest },
    },
  );

  return response.data;
};

export const trigger = async (jobName: string) => {
  const response = await axios.post(
    `${REACT_APP_API_SERVER_URL}/api/job/trigger/${jobName}`,
  );

  return response.status === 204;
};

export const disable = async (jobs: []) => {
  const response = await axios.post(
    `${REACT_APP_API_SERVER_URL}/api/job/disable`, {data: jobs}
  );

  return response.status === 204;
};
