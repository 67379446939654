import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiScheduledJobs } from '../../api';
import { PageRequest } from '../../types/PageRequest';

export const fetchAll = createAsyncThunk(
  'scheduledJobs/findAll',
  async (params: PageRequest, { rejectWithValue }) => {
    try {
      return await ApiScheduledJobs.findAll(params);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const trigger = createAsyncThunk(
  'scheduledJobs/trigger',
  async (jobName: string, { rejectWithValue }) => {
    try {
      return await ApiScheduledJobs.trigger(jobName);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const disable = createAsyncThunk(
  'scheduledJobs/disable',
  async (jobs: [], { rejectWithValue }) => {
    try {
      return await ApiScheduledJobs.disable(jobs);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);