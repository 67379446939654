import { useParams } from 'react-router-dom';
import { SimplePage } from '../../ui/simplePage/SimplePage';
import { Descriptions, Tag } from 'antd';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useEffect } from 'react';
import {
  ScheduledJobExecutionDetailsSelectors,
  ScheduledJobExecutionDetailsThunks,
} from '../../redux/scheduledJobExecutionDetails';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Loader } from '../../ui/loader/Loader';
import { getStatusTagColor } from '../scheduledJobExecutionPage/helper';
import { ExitMessageArea, StyledSubtitle } from './styled';

export const ScheduledJobExecutionDetailsPage: React.FC = () => {
  const { executionId } = useParams();

  const dispatch = useAppDispatch();

  const scheduledJobExecutionDetails = useAppSelector(ScheduledJobExecutionDetailsSelectors.selectResult);
  const scheduledJobExecutionDetailsLoading = useAppSelector(ScheduledJobExecutionDetailsSelectors.selectLoading);

  useEffect(() => {
    if (executionId) {
      dispatch(ScheduledJobExecutionDetailsThunks.fetchById({ id: +executionId }));
    }
  }, [dispatch, executionId]);

  return (
    <>
      <SimplePage
        title={`Execution details for execution ${scheduledJobExecutionDetails?.id}`}
        description="Please refresh the page to update job execution details"
        hasBackButton>
        <Descriptions title="Scheduled job execution details" layout="horizontal" column={1} bordered>
          <Descriptions.Item label="Execution id">{scheduledJobExecutionDetails?.id}</Descriptions.Item>
          <Descriptions.Item label="Job name">{scheduledJobExecutionDetails?.jobName}</Descriptions.Item>
          <Descriptions.Item label="Start time">{`${scheduledJobExecutionDetails?.startTime}`}</Descriptions.Item>
          <Descriptions.Item label="End time">{`${scheduledJobExecutionDetails?.endTime}`}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={getStatusTagColor(scheduledJobExecutionDetails?.status)}>
              {scheduledJobExecutionDetails?.status}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <StyledSubtitle level={2}>Exit message:</StyledSubtitle>
        <ExitMessageArea readOnly value={scheduledJobExecutionDetails?.exitMessage} rows={10} />
      </SimplePage>
      {scheduledJobExecutionDetailsLoading && <Loader />}
    </>
  );
};
