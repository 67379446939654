import { Button, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ScheduledJobExecutionSelectors, ScheduledJobExecutionThunks } from '../../redux/scheduledJobExecution';
import { ScheduledJobExecutionDto } from '../../types/ScheduledJobExecutionDto';
import { Loader } from '../../ui/loader/Loader';
import { SimplePage } from '../../ui/simplePage/SimplePage';
import { getStatusTagColor } from './helper';

export const ScheduledJobExecutionPage: React.FC = () => {
  const { jobName } = useParams();

  const dispatch = useAppDispatch();

  const scheduledJobExecutionPage = useAppSelector(ScheduledJobExecutionSelectors.selectResult);
  const scheduledJobExecutionPageLoading = useAppSelector(ScheduledJobExecutionSelectors.selectLoading);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (jobName) {
      dispatch(
        ScheduledJobExecutionThunks.fetchByJobName({
          jobName: jobName,
          pageRequest: { pageIndex, pageSize },
        }),
      );
    }
  }, [dispatch, jobName, pageIndex, pageSize]);

  const tableColumns = [
    {
      title: 'Execution id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Start time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'End time',
      dataIndex: 'endTime',
      key: 'endTime',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: ScheduledJobExecutionDto) => (
        <>
          <Tag color={getStatusTagColor(record.status)}>{record.status}</Tag>
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: ScheduledJobExecutionDto) => (
        <Space size="middle">
          <Link to={`/job-execution-details/${record.id}`}>
            <Button type="default">View details</Button>
          </Link>
        </Space>
      ),
    },
  ];

  const tableRows = scheduledJobExecutionPage?.content.map((row: ScheduledJobExecutionDto) => ({
    key: row.id,
    ...row,
  }));

  return (
    <>
      <SimplePage
        title={`Execution log for ${jobName}`}
        description="Please refresh the page to update job excution statuses."
        hasBackButton>
        <Table
          columns={tableColumns}
          dataSource={tableRows}
          pagination={{
            pageSize: pageSize,
            current: pageIndex + 1,
            total: scheduledJobExecutionPage && scheduledJobExecutionPage.totalPages * pageSize,
            onChange: (index, size) => {
              setPageIndex(index - 1);
              setPageSize(size);
            },
          }}
        />
      </SimplePage>
      {scheduledJobExecutionPageLoading && <Loader />}
    </>
  );
};
