import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiLogin } from "../../api";

export const login = createAsyncThunk(
  'auth/login',
  async (params: {username: string, password: string}, { rejectWithValue }) => {
    try {
      return await ApiLogin.login(params.username, params.password);
    } catch (err) {
      rejectWithValue(err);
    }
  }
)