export enum ScheduledJobExecutionStatus {
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
  FinishedWithErrors = 'FINISHED_WITH_ERRORS',
  Failed = 'FAILED',
}

export interface ScheduledJobExecutionDto {
  id: number;
  jobId: number;
  jobName: string;
  startTime: Date;
  endTime?: Date;
  status: ScheduledJobExecutionStatus;
  exitMessage?: string;
}
