import { createSlice } from '@reduxjs/toolkit';
import { PageResponse } from '../../types/PageResponse';
import { fetchAll, fetchByJobName } from './thunks';
import { ScheduledJobExecutionDto } from '../../types/ScheduledJobExecutionDto';

export interface ScheduledJobExecutionState {
  result?: PageResponse<ScheduledJobExecutionDto>;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ScheduledJobExecutionState = {
  result: undefined,
  loading: false,
  error: false,
  errorMessage: undefined
}

export const scheduledJobExecutionSlice = createSlice({
  name: 'scheduledJobExecutionSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAll.pending, (state) => {
      state.result = undefined;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.loading = true;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });

    builder.addCase(fetchByJobName.pending, (state) => {
      state.result = undefined;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchByJobName.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchByJobName.rejected, (state, action) => {
      state.loading = true;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });
  },
});

export const {} = scheduledJobExecutionSlice.actions;

export default scheduledJobExecutionSlice.reducer;
