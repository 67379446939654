import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../redux/login/slice';
import scheduledJobReducer from '../redux/scheduledJob/slice';
import scheduledJobExecutionReducer from '../redux/scheduledJobExecution/slice';
import scheduledJobExecutionDetailsReducer from '../redux/scheduledJobExecutionDetails/slice';

export const rootReducer = {
  login: loginReducer,
  scheduledJob: scheduledJobReducer,
  scheduledJobExecution: scheduledJobExecutionReducer,
  scheduledJobExecutionDetails: scheduledJobExecutionDetailsReducer
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
