import styled from "styled-components";

export const FullScreenLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  width: 100vw;
  height: 100vh;

  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  backdrop-filter: blur(10px);
`;
