import axios from 'axios';
import { REACT_APP_API_SERVER_URL } from '../../constants';
import { PageRequest } from '../types/PageRequest';
import { PageResponse } from '../types/PageResponse';
import { ScheduledJobExecutionDto } from '../types/ScheduledJobExecutionDto';

export const findAll = async (pageRequest: PageRequest) => {
  const response = await axios.get<PageResponse<ScheduledJobExecutionDto>>(
    `${REACT_APP_API_SERVER_URL}/api/job-execution`,
    {
      params: { ...pageRequest },
    },
  );

  return response.data;
};

export const findByJobName = async (
  jobName: string,
  pageRequest: PageRequest,
) => {
  const response = await axios.get<PageResponse<ScheduledJobExecutionDto>>(
    `${REACT_APP_API_SERVER_URL}/api/job-execution`,
    {
      params: { jobName: jobName, ...pageRequest },
    },
  );

  return response.data;
};
