import { REACT_APP_API_SERVER_URL } from '../../constants';
import axios from 'axios';
import { UserDto } from '../types/UserDto';
import base64 from 'base-64';

export const login = async (username: string, password: string) => {
  const response = await axios.get<UserDto>(
    `${REACT_APP_API_SERVER_URL}/api/auth/me`,
    {
      headers: {
        Authorization: 'Basic ' + base64.encode(username + ':' + password),
      },
    },
  );

  return response.status === 200;
};
