import { Button, Form, Input, notification } from 'antd';
import { PageContainer } from '../../ui/pageContainer/PageContainer';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoginForm, LoginFormButtonContainer, LoginFormContainer } from './styled';
import Title from 'antd/es/typography/Title';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { LoginSelectors, LoginThunks } from '../../redux/login';
import { useAppSelector } from '../../hooks/useAppSelector';
import { router } from '../../router/router';
import { Loader } from '../../ui/loader/Loader';
import React from 'react';

const Context = React.createContext({ name: 'Default' });

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useAppDispatch();

  const loggedIn = useAppSelector(LoginSelectors.selectLoggedIn);
  const loading = useAppSelector(LoginSelectors.selectLoading);
  const error = useAppSelector(LoginSelectors.selectError);

  const [api, contextHolder] = notification.useNotification();
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  const showLoginErrorNotification = useCallback(() => {
    api.error({
      message: 'Login unsuccessful',
      description: `Check your credentials and try again`,
    });
  }, [api]);

  const onSubmit = async () => {
    dispatch(LoginThunks.login({ username, password }));
  };

  useEffect(() => {
    if (loggedIn === true && loading === false && error === false) {
      localStorage.setItem('user', JSON.stringify({ username: username, password: password }));
      router.navigate('/jobs');
    }
    if (loggedIn === false && loading === false && error === true) {
      showLoginErrorNotification();
    }
    /* Even if eslint shows that username and password dependencies
     * should be used, they should not, because if used, useEffect
     * will update everytime a character is typed into username or
     * password inputs. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, loggedIn, showLoginErrorNotification]);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <PageContainer>
        <LoginFormContainer>
          <Title>Login</Title>
          <LoginForm onFinish={onSubmit} layout="vertical">
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username' }]}>
              <Input
                size="large"
                placeholder="Enter username"
                prefix={<UserOutlined />}
                onChange={(event) => setUsername(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password' }]}>
              <Input
                size="large"
                placeholder="Enter password"
                prefix={<LockOutlined />}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
              />
            </Form.Item>
            <LoginFormButtonContainer>
              <Button type="primary" htmlType="submit" size="large">
                Log in
              </Button>
            </LoginFormButtonContainer>
          </LoginForm>
        </LoginFormContainer>
      </PageContainer>
      {loading && <Loader />}
    </Context.Provider>
  );
};
