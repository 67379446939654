import { createSlice } from '@reduxjs/toolkit';
import { PageResponse } from '../../types/PageResponse';
import { fetchById } from './thunks';
import { ScheduledJobExecutionDto } from '../../types/ScheduledJobExecutionDto';

export interface ScheduledJobExecutionDetailsState {
  result?: ScheduledJobExecutionDto;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ScheduledJobExecutionDetailsState = {
  result: undefined,
  loading: false,
  error: false,
  errorMessage: undefined
}

export const scheduledJobExecutionDetailsSlice = createSlice({
  name: 'scheduledJobExecutionDetailsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchById.pending, (state) => {
      state.result = undefined;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchById.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchById.rejected, (state, action) => {
      state.loading = true;
      state.error = true;
      state.errorMessage = action.payload
        ? (action.payload as Error)?.message
        : action.error?.message;
    });
  },
});

export const {} = scheduledJobExecutionDetailsSlice.actions;

export default scheduledJobExecutionDetailsSlice.reducer;
