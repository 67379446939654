import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiScheduledJobExecutions } from '../../api';
import { PageRequest } from '../../types/PageRequest';

export const fetchAll = createAsyncThunk(
  'scheduledJobExecutions/findAll',
  async (params: PageRequest, { rejectWithValue }) => {
    try {
      return await ApiScheduledJobExecutions.findAll(params);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const fetchByJobName = createAsyncThunk(
  'scheduledJobExecutions/findByName',
  async (params: { jobName: string, pageRequest: PageRequest }, { rejectWithValue }) => {
    try {
      return await ApiScheduledJobExecutions.findByJobName(params.jobName, params.pageRequest);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);
