import axios from "axios";
import { ScheduledJobExecutionDto } from "../types/ScheduledJobExecutionDto";
import { REACT_APP_API_SERVER_URL } from "../../constants";

export const findById = async (id: number) => {
  const response = await axios.get<ScheduledJobExecutionDto>(
    `${REACT_APP_API_SERVER_URL}/api/job-execution/${id}`,
  )

  return response.data;
}
