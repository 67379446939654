import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title"
import styled from "styled-components"

export const StyledSubtitle = styled(Title)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ExitMessageArea = styled(TextArea)`
  font-family: monospace;
`;