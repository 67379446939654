import { CSSObject } from 'styled-components';

type ScreenSize = keyof typeof mediaQueryMap;

type MediaQueryEnhancer = (
  screenSize: ScreenSize,
  style: CSSObject,
) => CSSObject;

type MediaQueryType = (screenSize: ScreenSize) => string;

export enum ScreenBreakpoints {
  xs = 575,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

export const mediaQueryMap = {
  xs: `(max-width: ${ScreenBreakpoints.xs}px) `,
  sm: `(min-width: ${ScreenBreakpoints.sm}px) `,
  md: `(min-width: ${ScreenBreakpoints.md}px) `,
  lg: `(min-width: ${ScreenBreakpoints.lg}px) `,
  xl: `(min-width: ${ScreenBreakpoints.xl}px) `,
  xxl: `(min-width: ${ScreenBreakpoints.xxl}px) `,
};

export const mediaQueryEnhancer: MediaQueryEnhancer = (screenSize, style) => ({
  [`${mediaQuery(screenSize)}`]: style,
});

export const mediaQuery: MediaQueryType = (screenSize) =>
  `@media ${mediaQueryMap[screenSize]}`;

export const isDesktopResolution = () =>
  window.innerWidth > ScreenBreakpoints.md;

export const isMobileResolution = () =>
  window.innerWidth <= ScreenBreakpoints.md;
