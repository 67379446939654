import { useNavigate } from 'react-router-dom';
import { PageContainer } from '../pageContainer/PageContainer';
import { SimplePageBackButton, SimplePageDescription, SimplePageTitle } from './styled';
import { PropsWithChildren } from 'react';

export interface SimplePageProps {
  title: string;
  description?: string;
  hasBackButton?: boolean;
}

export const SimplePage: React.FC<PropsWithChildren<SimplePageProps>> = ({
  title,
  description,
  hasBackButton,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      {hasBackButton && <SimplePageBackButton onClick={() => navigate(-1)}>Back</SimplePageBackButton>}
      <SimplePageTitle>{title}</SimplePageTitle>
      {description && <SimplePageDescription>{description}</SimplePageDescription>}
      {props.children}
    </PageContainer>
  );
};
