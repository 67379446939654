import { Button, Space, Table, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ScheduledJobSelectors, ScheduledJobThunks } from '../../redux/scheduledJob';
import { resetTriggerState } from '../../redux/scheduledJob/slice';
import { ScheduledJobDto } from '../../types/ScheduledJobDto';
import { Loader } from '../../ui/loader/Loader';
import { SimplePage } from '../../ui/simplePage/SimplePage';

const Context = React.createContext({ name: 'Default' });

export const ScheduledJobPage = () => {
  let jobs = [
    { name: 'sync-hibob-employees-job', status: true },
    { name: 'sync-hibob-salaries-job', status: true },
    { name: 'sync-hibob-timeoffs-job', status: true },
    { name: 'sync-hibob-travel-orders-job', status: true },
    { name: 'sync-hibob-bonuses-job', status: true },
  ];
  const [isButtonDisabled, setButtonDisabled] = useState<any>(jobs);

  const dispatch = useAppDispatch();

  const scheduledJobPage = useAppSelector(ScheduledJobSelectors.selectResult);
  const scheduledJobPageLoading = useAppSelector(ScheduledJobSelectors.selectLoading);
  const scheduledJobTriggered = useAppSelector(ScheduledJobSelectors.selectTriggered);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [triggeredJobName, setTriggeredJobName] = useState<string>();

  const [api, contextHolder] = notification.useNotification();
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  useEffect(() => {
    dispatch(ScheduledJobThunks.fetchAll({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  const triggerJob = useCallback(
    (jobName: string) => {
      dispatch(ScheduledJobThunks.trigger(jobName));
      setTriggeredJobName(jobName);
    },
    [dispatch],
  );

  const showJobTriggeredNotification = useCallback(
    (jobName?: string) => {
      api.info({
        message: 'Job triggered!',
        description: `Job ${jobName} has started`,
      });
    },
    [api],
  );

  const setInitialJob = useCallback(() => {
    if (scheduledJobPage) {
      jobs = scheduledJobPage.content.map((row: ScheduledJobDto) => ({
        name: row.name,
        status: row.jobEnabled,
      }));
      setButtonDisabled(jobs);
    }
  }, [dispatch, scheduledJobPage]);

  const triggerDisableJob = useCallback(
    (jobs: []) => {
      dispatch(ScheduledJobThunks.disable(jobs));
    },
    [dispatch],
  );

  useEffect(() => {
    if (scheduledJobTriggered) {
      showJobTriggeredNotification(triggeredJobName);
      dispatch(resetTriggerState());
      setTriggeredJobName('');
    }
  }, [scheduledJobTriggered, showJobTriggeredNotification, dispatch, triggeredJobName]);

  useEffect(() => {
    setInitialJob();
  }, [dispatch, scheduledJobPage]);

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: ScheduledJobDto) => (
        <Space size="middle">
          <Button
            disabled={isButtonDisabled.filter((d: any) => d.name === record.name)[0].status === true ? false : true}
            type={
              isButtonDisabled.filter((d: any) => d.name === record.name)[0].status === true ? 'primary' : 'default'
            }
            onClick={() => triggerJob(record.name)}>
            Run now
          </Button>
          <Link to={`/job-execution-log/${record.name}`}>
            <Button type="default">Execution log</Button>
          </Link>
          <Button
            type={
              isButtonDisabled.filter((d: any) => d.name === record.name)[0].status === true ? 'default' : 'primary'
            }
            onClick={() => {
              let s = false;
              isButtonDisabled.forEach((element: any, index: number) => {
                if (element.name === record.name) {
                  s = element.status;
                  isButtonDisabled.splice(index, 1);
                }
              });
              let arr: any = [...isButtonDisabled, { name: record.name, status: !s }];
              setButtonDisabled(arr);
              triggerDisableJob(arr);
            }}>
            {isButtonDisabled.filter((d: any) => d.name === record.name)[0].status === true
              ? 'Disable job'
              : 'Enable job'}
          </Button>
        </Space>
      ),
    },
  ];

  const tableRows = scheduledJobPage?.content.map((row: ScheduledJobDto) => ({
    key: row.name,
    ...row,
  }));

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <SimplePage title="Scheduled jobs">
        <Button
          style={{ float: 'right', marginBottom: '10px' }}
          type={isButtonDisabled.filter((d: any) => d.status === false).length === 0 ? 'default' : 'primary'}
          onClick={() => {
            let check = isButtonDisabled.filter((d: any) => d.status === false).length === 0;
            if (check) {
              isButtonDisabled.forEach((element: any, index: number) => {
                element.status = false;
              });
            } else {
              isButtonDisabled.forEach((element: any, index: number) => {
                element.status = true;
              });
            }
            setButtonDisabled([...isButtonDisabled]);
            triggerDisableJob(isButtonDisabled);
          }}>
          {isButtonDisabled.filter((d: any) => d.status === false).length === 0 ? 'Disable jobs' : 'Enable jobs'}
        </Button>
        <Table
          columns={tableColumns}
          dataSource={tableRows}
          pagination={{
            pageSize: pageSize,
            current: pageIndex + 1,
            total: scheduledJobPage && scheduledJobPage.totalPages * pageSize,
            onChange: (index, size) => {
              setPageIndex(index - 1);
              setPageSize(size);
            },
          }}
        />
      </SimplePage>
      {scheduledJobPageLoading && <Loader />}
    </Context.Provider>
  );
};
