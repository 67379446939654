import { ScheduledJobExecutionStatus } from "../../types/ScheduledJobExecutionDto";

export const getStatusTagColor = (executionStatus?: ScheduledJobExecutionStatus) => {
  if (executionStatus === ScheduledJobExecutionStatus.InProgress) {
    return 'orange';
  } else if (executionStatus === ScheduledJobExecutionStatus.Finished) {
    return 'green';
  } else if (executionStatus === ScheduledJobExecutionStatus.FinishedWithErrors) {
    return 'volcano';
  } else if (executionStatus === ScheduledJobExecutionStatus.Failed) {
    return 'red';
  }
};