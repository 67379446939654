import { Button, Card } from "antd";
import Title from "antd/es/typography/Title";
import styled from "styled-components";

export const SimplePageBackButton = styled(Button)`
  margin-top: 2rem;
`;

export const SimplePageTitle = styled(Title)`
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

export const SimplePageDescription = styled(Card)`
  margin-top: -1rem;
  margin-bottom: 2rem;
`;