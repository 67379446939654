import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { LoginPage } from '../pages/loginPage/LoginPage';
import { ScheduledJobPage } from '../pages/scheduledJobPage/ScheduledJobPage';
import { ScheduledJobExecutionPage } from '../pages/scheduledJobExecutionPage/ScheduledJobExecutionPage';
import { ScheduledJobExecutionDetailsPage } from '../pages/scheduledJobExecutionDetailsPage/ScheduledJobExecutionDetailsPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/jobs',
    element: localStorage.getItem('user') ? <ScheduledJobPage /> : <Navigate to={{ pathname: '/' }} />,
  },
  {
    path: '/job-execution-log/:jobName',
    element: <ScheduledJobExecutionPage />,
  },
  {
    path: '/job-execution-details/:executionId',
    element: <ScheduledJobExecutionDetailsPage />,
  },
]);
