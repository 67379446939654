import styled from 'styled-components';
import { mediaQuery, ScreenBreakpoints } from '../../util/mediaQuery';

export const PageContainer = styled.div`
  margin: 0 auto;
  padding: 1rem;

  ${mediaQuery('md')} {
    max-width: ${`${ScreenBreakpoints.xl}px`};
    padding: 0;
  }
`;
